.DigitalCatalog
  padding-top: 20px
  &.phone
    padding-top: 16px

.menuLeft
  width: 52px
  box-sizing: border-box
  padding-right: 20px
  transition: all 0.2s ease-in-out
  &.true
    width: 200px

.menuIcon
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  width: 32px
  padding: 4px
  cursor: pointer

.openIcon
  font-size: 24px

.menuContainer
  background-color: var(--primary-background)
  height: 100%
  border-radius: 8px
  display: flex
  overflow: hidden
  align-items: center
  max-height: 700px

.menuScroll
  height: 100%
  width: 100%
  padding: 8px
  padding-top: 0px
  overflow-y: auto

.slideContainer
  background-color: var(--cream-color-background)
  border-radius: 8px

.catalogRight
  width: calc( 100% - 52px )
  transition: all 0.2s ease-in-out
  &.true
    width: calc( 100% - 200px )

.sliderContent
  width: 100%
  display: flex
  justify-content: center

.imageContainer
  width: 95%
  display: flex
  justify-content: center

.pageImage
  width: auto
  max-width: 100%
  max-height: 700px
  display: block

.miniContainer
  width: 100%
  padding-top: 8px

.miniImage
  width: 100%
  display: block
  border: 1px solid transparent
  cursor: pointer
  &.true
    border-color: var(--primary-colorgold)

.arrowsContainer
  display: flex
  justify-content: center
  margin-right: 16px

.arrow
  width: 40px
  height: 40px
  border-radius: 25px
  border: 1px solid var(--primary-border-color-gray)
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  transition: all 0.2s ease-in-out
  &:hover
    background-color: var(--primary-colorblue)
    .arrowIcon
      color: var(--white)

.arrowIcon
  font-size: 24px
  color: var(--primary-colorblue)
  transition: all 0.2s ease-in-out

.arrowRight
  margin-left: 8px

.controlsContainer
  padding: 16px 40px
  display: flex
  justify-content: flex-end

.zoomButton
  background-color: var(--primary-background)
  padding: 8px
  border-radius: 8px
  cursor: pointer
  margin-left: 8px
  display: flex
  justify-content: center
  align-items: center

.catalogContainer
  width: 100%
  height: calc(100vh - 80px)
