.HeroWithVideo
  &.phone
    .videoCard
      width: 80%
      height: 320px
    .container
      flex-direction: column-reverse
    .mainContainer
      padding: 22px 22px
    .textContainer, .videoContainer
      width: 100%
    .videoContainer
      height: 250px
    .title
      font-size: 22px
    .subtitle
      font-size: 22px
      margin-bottom: 16px

.mainContainer
  background-color: var(--primary-background)
  padding: 24px 80px
  width: 100%

.container
  display: flex

.textContainer
  width: 50%

.videoContainer
  width: 50%
  height: 350px
  background: var(--primary-background)
  position: relative
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  background-position: center
  background-repeat: no-repeat
  background-size: cover

#myVideo
  position: absolute
  right: 0
  bottom: 0
  min-width: 100%
  height-height: 100%
  opacity: 0.6

.textContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.title
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 28px
  text-align: center

.subtitle
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 28px
  text-align: center

.button
  background-color: var(--primary-colorblue)
  color: var(--white)
  padding: 8px
  border-radius: 8px
  margin-top: 16px
  cursor: pointer
  &:hover
    background-color: var(--primary-colorgold)

.playImage
  width: 60px
  height: 60px
  position: relative
  cursor: pointer

.youtubeContainer
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  z-index: 9
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

.overlay
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background-color: rgba($color: #000000, $alpha: 0.5)

.videoCard
  position: relative
  background-color: #ffffff
  width: 800px
  height: 500px
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center

.closeVideo
  color: #ffffff
  font-size: 28px
  position: absolute
  top: 20px
  right: 20px
  cursor: pointer

.imageBG
  position: absolute
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0.6
