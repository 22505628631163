#Header
  position: sticky
  top: 0
  z-index: 9
  background-image: linear-gradient(#002747,#01192a)
  &.phone
    .languageSelect
      position: absolute
      right: 20px
      top: 15px
  &.tablet
    .menuLeft
      align-items: left
      width: 100%

.HeaderContainer
  display: flex
  align-items: center
  padding-left: 16px
  padding-right: 16px

.headerLogo
  display: flex
  justify-content: center
  width: 100%
  padding: 16px

.centerLogo
  width: 120px

.flagContainer
  padding-right: 16px
  display: flex
  align-items: center

.flagImage
  width: 30px
  cursor: pointer
  transition: all .2s ease-in-out
  &:hover
    transform: scale(1.15)

.left
  padding-right: 16px

.headerActions
  display: flex
  justify-content: flex-end
  color: #ffffff
  padding: 16px
  align-items: center
  box-sizing: border-box

.preguntas
  margin-left: 16px
  font-size: 13px
  padding-right: 20px
  color: hsla(0,0%,100%,.7)
  font-family: 'Raleway', sans-serif
  font-weight: 400
  text-decoration: none
  box-sizing: border-box
  img
    margin-left: 6px
    height: 13px
    width: auto
    opacity: .7

.button
  background-color: #9b722a
  color: #ffffff
  font-size: 13px
  border-radius: 6px
  padding: 8px
  font-family: 'Raleway', sans-serif
  font-weight: 700
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  box-sizing: border-box
  margin-right: 16px
  &:hover
    text-decoration: underline
  img
    margin-left: 6px
    height: 13px
    width: auto
    opacity: .7

.flags
  opacity: .7
  box-sizing: border-box
  padding-left: 20px

.logo
  display: flex
  justify-content: center
  img
    height: auto
    width: 120px
  i
    font-size: 35px
    margin-right: 20px
    cursor: pointer
    color: var(--primary-text-gray)
  &.phone
    padding: 8px 0px
    img
      height: 50px

.languageSelect
  color: #ffffff
  padding-left: 20px
  position: relative

.languageSelected
  padding: 10px
  border: 1px solid #ffffff
  cursor: pointer

.optionsContainer
  padding-left: 20px
  position: relative

.optionsContent
  position: absolute
  background-color: #ffffff
  box-sizing: border-box
  width: 100%
  padding: 0px
  top: 100%
  left: 0
  height: 0
  overflow: hidden
  display: flex
  flex-direction: column
  transition: all 200ms ease-out
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
  border-radius: 0px 0px 4px 4px
  z-index: 2
  &.true
    padding: 10px 0px
    height: auto
  .languageOption
    text-decoration: none
    color: #000000
    font-size: 14px
    padding: 20px 0px
    display: flex
    justify-content: center
    cursor: pointer
    &:hover
      background-color: #f5f5f5

.buttonROP
  margin-left: 1px
  background-color: #002747
  border: 1px solid #ffffff
  color: #ffffff
  font-size: 13px
  border-radius: 6px
  padding: 8px
  font-family: 'Raleway', sans-serif
  font-weight: 700
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  box-sizing: border-box
  float: left
  &:hover
    background-color: #9b722a
    border: 1px solid #9b722a

.cssROP
  float: left !important
  margin-left: 10px
  margin-right: 1px
  display: flex
  justify-content: flex-end
  color: #ffffff
  padding: 16px
  align-items: center
  box-sizing: border-box

.center
  flex: 1

.menuLeft
  // align-items: 
  width: 99%

.right
  display: flex
  margin-left: 15px

.left
  display: flex
  align-items: center
  height: 100%

.shopIcon
  font-size: 24px
  color: var(--white)
  cursor: pointer

.cart
  background-color: var(--primary-colorgold)
  color: var(--white)
  padding: 7px 15px
  border-radius: 100px
  font-size: 13px
  cursor: pointer
  font-weight: 500
  &:hover
    text-decoration: underline

.appRedirectContainer
  display: flex
  justify-content: space-between
  padding: 8px
  background-color: var(--primary-colorgray)
  align-items: center

.redirectIcon
  display: flex
  align-items: center

.redirectText
  flex: 1
  padding: 0px 16px

.redirectTitle
  font-weight: 600
  color: var(--color-gray)

.redirectSubtitle, .redirectStore
  color: var(--primary-text-gray)

.appIcon
  width: 60px

.redirectButton
  color: var(--highlight-color)
  display: flex
  width: 50px
  justify-content: center
  padding: 8px
  font-weight: 600
  cursor: pointer
