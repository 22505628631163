.container
  position: relative

.zoom
  background-position: 50% 50%
  position: relative
  overflow: hidden
  cursor: zoom-in
  margin-block-start: 0px
  margin-block-end: 0px
  margin-inline-start: 0px
  margin-inline-end: 0px
  img
    transition: opacity 0.5s
    display: block
    width: 100%
    &:hover
      opacity: 0

.zoomIcon, .closeIcon
  position: absolute
  background-color: rgba(255, 255, 255, 0.8)
  width: 40px
  height: 40px
  display: flex
  justify-content: center
  align-items: center

.zoomIcon
  bottom: 0px
  right: 0px
  color: var(--primary-colorgold)
  i
    font-size: 24px

.closeIcon
  top: 0px
  right: 0px
  color: var(--primary-colorblue)
  i
    font-size: 24px
