.container
  min-height: 100vh
  &.phone
    .inputRow
      flex-direction: column
      row-gap: 16px
    .consultantData
      flex-direction: column
      row-gap: 16px
    .column
      width: 90px

.orderContainer
  min-height: 100vh
  width: 100%
  max-width: 1400px
  margin: 0 auto
  padding: 24px 24px 60px

.orderTable
  font-size: 14px

.row
  display: flex
  border-bottom: 1px solid var(--coloer-border-gray-lite)
  padding: 8px

.rowItem
  padding: 16px 8px
  flex-wrap: wrap

.column
  width: 140px
  display: flex
  align-items: center
  justify-content: center

.descri
  width: 100%
  flex: 1

.title
  color: var(--primary-colorblue)
  font-weight: 500

.itemImage
  width: 48px
  height: 48px

.itemCode
  color: var(--primary-colorgold)

.itemDesc
  color: var(--primary-colorblue)

.itemData
  color: var(--primary-text-gray)

.resumeContainer
  display: flex
  justify-content: flex-end

.resumeTitle
  text-align: right
  margin-bottom: 8px
  color: var(--primary-colorblue)
  font-weight: 500

.resumeTotal
  display: flex
  justify-content: flex-end
  color: var(--primary-colorblue)

.resumeAmount
  color: var(--primary-colorgold)
  font-weight: 600
  margin-bottom: 8px

.resumeNumber
  width: 140px
  text-align: right

.rightContainer
  border-bottom: 1px solid var(--coloer-border-gray-lite)
  margin: 24px 0px

.cardContainer
  background-color: var(--primary-background)
  border-radius: 8px
  padding: 16px 16px 32px 16px

.inputRow
  display: flex

.container50
  width: 50%

.container100
  width: 100%

.container33
  width: 33.333333333333%

.container50, .container33
  padding: 0px 16px

.marginTop
  margin-top: 16px

.dataTitle
  padding-left: 16px
  font-size: 18px
  font-weight: 500
  color: var(--primary-colorblue)
  margin-bottom: 16px

.margin24
  margin-top: 24px

.cardHeader
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px
  margin-top: 16px

.cardTitle
  color: var(--primary-colorgold)
  font-weight: 600
  font-size: 18px
  display: flex
  align-items: center

.rightText
  color: var(--primary-text-gray)
  font-size: 14px

.icon
  margin-right: 8px

.consultantData
  display: flex
  justify-content: space-between

.consultantRow
  display: flex

.consultantTitle
  color: var(--primary-colorblue)
  font-weight: 500
  padding-right: 8px

.consultantText
  color: var(--primary-colorgold)
  font-weight: 300

.infoContainer
  padding-top: 16px

.infoTitle
  color: var(--primary-colorblue)
  font-weight: 500

.infoText
  color: var(--primary-text-gray)
  font-weight: 300

.buttonContainer
  display: flex
  justify-content: flex-end
  margin-top: 16px

.button
  width: 200px
  height: 50px
  background-color: var(--primary-colorblue) !important
  color: var(--white) !important
  font-weight: 600 !important
  padding: 8px 0px !important
  border-radius: 8px !important
  &:hover
    background-color: var(--primary-colorgold) !important
    color: var(--white) !important

.buttonDisabled
  background-color: var(--color-disabled) !important
  &:hover
    background-color: var(--color-disabled) !important

.taxesWarning
  color: var(--primary-text-gray)
  font-size: 12px

.circularProgress
  color: var(--white) !important
  width: 18px !important
  height: 18px!important

.circularProgressOrder
  color: var(--primary-colorblue) !important

.loadingOrder
  display: flex
  justify-content: center
  padding: 16px 0px

.quantityMobile
  display: flex
  margin-top: 16px
  justify-content: space-between
  width: 100%

.policiesContainer
  display: flex
  color: var(--primary-text-gray)
  font-size: 14px
  align-items: center
  margin-top: 16px

.check
  color: var(--primary-colorgold) !important
  padding-top: 0px !important
  padding-bottom: 2px !important

.legalLink
  color: var(--primary-colorgold)
  cursor: pointer
  &:hover
    text-decoration: underline
