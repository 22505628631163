#Joinup
  font-family: var(--poppins) !important
  &.phone
    .bloque
      flex-direction: column
    .img
      width: 100% !important

  .Text
    text-align: justify
    margin-top: 35px
    font-size: 14px
    line-height: 27px
    margin-bottom: 35px
    background: var(--primary-background)
    border-radius: 10px
    color: var(--primary-text-gray)
    padding: 16px
    margin: 16px

  #Titles
    background: var(--primary-background)
    .title
      color: var(--primary-colorblue)
      font-size: 32px
      font-weight: 500
      text-align: center
      font-family: var(--subtitle-font)
      margin-top: 0px

    .subtitle
        color: var(--primary-colorgold)
        font-size: 30px
        margin: 0
        font-family: var(--title-font)
        text-align: center 
        margin-top: 5px
        font-weight: 500
        text-transform: uppercase

    .subtitle2
        color: var(--primary-colorblue)
        font-size: 15px
        font-family: var(--poppins)
        text-align: center 
        margin-top: 5px
        font-weight: 450
        height: 40px

  .titles
      color: var(--primary-colorblue)

  .bloque
      display: flex !important

  .subBloque
      padding-right: 40px !important
      &.phone
        padding-right: 1px !important

  .img
      width: 230px !important
  
  .titleParagraph
      font-size: 15px
      color: var(--primary-colorblue)
      font-weight: 600
      margin-top: 10px !important
      margin-bottom: 7px !important

    
