.Footer
  background-color: var(--white)
  font-family: var(--poppins)

.preFooter
  padding: 24px

.middleFooter
  background-color: var(--primary-background)
  padding: 24px
