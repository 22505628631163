.sectionTitle
  width: 100%
  text-align: center
  margin-bottom: 24px

.titleText
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 32px

.subtitleText
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 30px

.subtitleGrayText
  font-family: var(--poppins)
  color: var(--primary-text-gray)
  font-size: 14px
