#Product
  font-family: var(--poppins) !important
  display: flex
  position: relative
  &.tablet
    .data
      width: 100%
    .imageSuperContainer
      img
        position: sticky
        top: 60px
        padding-bottom: 60px
  &.phone
    flex-direction: column
    .dataContainer, .data, .imageSuperContainer
      width: 100%
    .dataContainer
      padding-top: 16px
    .data
      padding: 0px 15px 15px 15px
    .productName
      padding: 5 16px
      text-align: center
      width: 100%
      font-size: 18px
    .imageSuperContainer
      margin: 0px
      padding: 16px

.imageSuperContainer
  width: 35%
  padding-top: 10px
  margin: 30px 30px 10px 20px

.imageContainer
  width: 100%
  background: var(--primary-background)
  border-radius: 30px
  text-align: center
  position: sticky
  top: 90px

.dataContainer
  width: 65%
  padding-top: 40px

.productName
  color: var(--primary-colorgold)
  font-size: 24px
  font-weight: 500
  margin-bottom: 10px

.dataContent
  display: flex
  width: 100%
  flex-wrap: wrap

.data
  box-sizing: border-box
  width: 33.33%
  padding: 15px 15px 15px 0px

.textContainer
  border: 1px solid #cccccc
  padding: 15px
  border-radius: 10px

.title, strong
  color: #002747
  font-weight: 700
  text-decoration: underline
  font-size: 1.05rem

.info
  font-size: .95rem
  line-height: 1.3rem

.textColor
  font-family: var(--poppins) !important
  font-size: 14px !important
  color: var(--primary-text-gray)

.tabColor
  color: var(--primary-text-gray) !important
  text-transform: capitalize !important
  font-family: var(--poppins) !important
  font-weight: 600 !important

.imgContainer
  width: 80% !important
