.ThreeBanners
  &.tablet
    .ThreeBannersContainer
      padding: 0px 40px
  &.phone
    .ThreeBannersContainer
      flex-direction: column
      height: auto
      padding: 0px
    .oneBanner, .twoBanners
      width: 100%
      height: auto
      padding: 0px
    .oneBanner, .bannerTop, .bannerBottom
      height: 350px
      padding: 0px
    .oneBanner, .bannerTop
      margin-bottom: 8px
    .bannerTitle
      font-size: 24px
    .bannerText
      font-size: 14px
    .videoCard
      width: 80%
      height: 320px

.ThreeBannersContainer
  display: flex
  height: 600px
  padding: 0px 80px

.bannerText
  font-size: 16px

.oneBanner, .twoBanners
  height: 100%
  width: 50%

.oneBanner
  padding-right: 4px

.twoBanners
  padding-left: 4px

.bannerTop, .bannerBottom
  height: 300px

.bannerTop
  padding-bottom: 4px

.bannerBottom
  padding-top: 4px

.oneImage, .topImage, .bottomImage
  background-color: var(--primary-background)
  height: 100%
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  display: flex
  flex-direction: column-reverse
  cursor: pointer
  transition: all 0.15s ease-in-out
  position: relative
  overflow: hidden
  &:hover
    opacity: 0.7
    .imageBG
      transform: scale(1.05)

.bannerTextContainer
  background: rgb(0,0,0)
  background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0.3)60%, rgba(0,0,0,0) 100%)
  padding: 20px
  color: var(--white)
  position: relative

.bannerTitle
  font-size: 32px
  font-family: var(--title-font)
  margin-bottom: 8px

.bannerText
  font-size: 16px

.imageBG
  position: absolute
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  top: 0
  bottom: 0
  left: 0
  right: 0
  transition: all 0.2s ease-in-out

.youtubeContainer
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  z-index: 9
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

.overlay
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background-color: rgba($color: #000000, $alpha: 0.5)

.closeVideo
  color: var(--white)
  font-size: 28px
  position: absolute
  top: 20px
  right: 20px
  cursor: pointer

.videoCard
  position: relative
  background-color: var(--white)
  width: 800px
  height: 500px
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
