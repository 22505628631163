.calendarContainer
    padding: 32px
    width: 100%
    height: 100%
    box-sizing: border-box
    background-color: #588ce5
    &.phone
        .cardFront
            font-size: 10px
        .cardBack, .promoExpired
            font-size: 8px
        .adventContainer
            grid-template-columns: repeat(4, 1fr)

.adventContainer
    position: relative
    display: grid
    grid-template-columns: repeat(6, 1fr)
    width: 100%
    height: 100%

.dayContainer
    border: 1px dashed #ccc
    cursor: pointer
    perspective: 1000px
    &:hover
        .cardInner
            transform: rotateY(180deg)
        .cardFront
            background-color: var(--primary-colorblue)

.cardInner
    position: relative
    width: 100%
    height: 100%
    text-align: center
    transform-style: preserve-3d
    transition: transform 0.5s
    &.isActive
        transform: rotateY(180deg)

.cardFront, .cardBack
    position: absolute
    width: 100%
    height: 100%
    backface-visibility: hidden

.cardFront
    color: black
    transition: background-color 0.2s
    color: white
    font-weight: bold
    padding: 16px
    text-shadow: 1px 1px 0px #000
    font-size: 14px

.cardBack
    background-color: #2980b9
    color: white
    transform: rotateY(180deg)

.promoImage
    position: absolute
    z-index: 2
    transition: all 0.5s
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.giftImageContainer
    position: absolute
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    z-index: 2

.giftImage
    width: 100%
    height: 100%
    object-fit: cover

.close
    background-color: black
    border-radius: 9999px
    color: white
    display: flex
    justify-content: center
    align-items: center
    width: 40px
    height: 40px
    position: absolute
    right: 10px
    top: 10px
    box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2)
    cursor: pointer

.noPromo
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

.promoExpired
    background-color: red
    color: white
    font-size: 12px
    font-weight: bold