#Videos
    display: flex
    padding: 16px 16px
    font-family: var(--poppins)
    &.phone
        flex-direction: column
        padding: 15px 0px
        .categoriesContainer, .thumbsContainer, .videoContainer, .thumbsContainerframe
            width: 100%
            height: auto !important
            margin: 15px 0px
            margin-top: 45px !important
        .thumbs
            max-height: 900px !important
        .thumbImage
            background-size: cover
            background-position: 50%
            height: 180px
            width: 100%
            display: block
        .strong
            font-size: 16px
            font-weight: 600

.loading
    display: flex
    box-sizing: border-box
    padding: 20px
    justify-content: center

.categoriesContainer
    width: 15%
    box-sizing: border-box
    padding: 0px 15px
    margin-top: 50px

.thumbsContainer
    width: 20%
    box-sizing: border-box
    padding: 0px 15px
    margin-top: 50px

.thumbsContainerframe
    width: 75%
    box-sizing: border-box
    padding: 0px 15px
    margin-top: 50px

.videoContainer
    width: 70%
    h2
        margin-top: 0px
        text-align: center !important
        font-family: var(--title-font)
        color: var(--primary-colorblue)
        font-weight: 600
        font-size: 28px !important

.categories
    padding: 5px
    border-radius: 10px
    color: var(--primary-text-gray)
    font-size: 14px
    box-sizing: border-box
    font-family: var(--poppins) !important

.category
    flex: 1
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    color: var(--primary-text-gray)
    padding: 8px 0px
    border-radius: 8px 8px 0px 0px
    transition: all 100ms ease-out
    i
        color: var(--primary-text-gray)
        font-size: 18px
        transition: all 200ms ease-out
    span
        font-weight: 400
        font-size: 16px
    &.true
        color: var(--primary-colorblue)

.thumbs
    border-radius: 10px
    max-height: 600px
    width: 100% !important
    overflow-y: auto
    display: block
    background: var(--primary-background)

.thumbBox
    cursor: pointer
    width: 95%
    display: block
    padding-block-end: 15px
    padding-block-start: 15px
    padding-left: 16px
    padding-right: 5px
    margin-top: 7px
    .strong
        display: block
        font-size: 14px
        text-decoration: none
        color: var(--primary-colorblue)
        cursor: pointer
        margin-top: 0px
        padding: 10px 10px
        text-align: center
        font-weight: 600
    &.true
        background-color: var(--primary-background)
        color: var(--primary-colorgold) !important
        .strong
            display: block
            text-align: center
            font-size: 14px
            color: var(--white)
            background: var(--primary-colorblue)

.thumbImage
    background-size: cover
    background-position: 50%
    height: 120px
    width: 100%
    display: block

.youtube
    display: block
    margin: 4px auto
