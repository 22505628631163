#Videos
    margin-top: 00px
    margin-bottom: 0px
    &.phone
        .thumbs
            flex-direction: column
        .videoContainer
            width: 100%
        .title
            margin-top: 0px

.loading
    display: flex
    box-sizing: border-box
    padding: 20px
    justify-content: center

.circularProgressStyle
    color: #002747 !important

.thumbsContainer
    display: flex
    align-content: center
    padding: 0px 10px

.thumbs
    display: flex
    align-items: center
    width: 100%

.youtube
    display: block
    margin: 10px auto

.videoContainer
    width: 50%

.title
    font-weight: 700
    font-size: 22px
    width: 100%
    text-align: center
    margin: 40px 0px 20px

.SubtitleBlue
    font-family: var(--subtitle-font)
    font-size: 22px
    font-weight: 600
    color: var(--primary-colorblue)

.SubtitleGold
    font-family: var(--title-font)
    color: var(--primary-colorgold)
    text-transform: uppercase
    font-size: 22px
    font-weight: 600
