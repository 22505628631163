.menu
  width: 100%
  padding: 24px
  box-sizing: border-box
  height: 100%
  overflow-y: auto

.title
  color: var(--primary-colorgold)
  font-size: 24px
  padding-bottom: 16px
