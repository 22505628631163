.label
  border-radius: 8px
  display: flex
  border: 1px solid var(--primary-text-gray)
  cursor: text
  background-color: var(--white)

.labelTextArea
  border-radius: 8px
  position: relative
  display: flex
  z-index: 0
  border: 1px solid var(--primary-text-gray)
  outline: none
  cursor: text
  height: 100%
  background-color: var(--white)

.labelError
  border: 1px solid red

.text
  cursor: inherit
  font-weight: 500
  font-size: 16px
  padding-bottom: 8px

.textActive
  color: var(--primary-colorblue)

.textInactive
  color: var(--primary-colorblue)

.textError
  color: var(--red-alert)

.input
  font-size: 16px
  padding: 8px 8px 8px 16px
  color: var(--primary-text-gray)
  -webkit-tap-highlight-color: transparent
  font-weight: 300
  width: 100%
  height: 50px
  background-color: transparent
  touch-action: manipulation
  border: none
  outline: none
  font-family: var(--poppins)

.textArea
  font-size: 1rem
  padding: 18px 16px
  color: var(--primary-text-gray)
  -webkit-tap-highlight-color: transparent
  font-weight: normal
  width: 100%
  background-color: transparent
  line-height: 1.25
  touch-action: manipulation
  border: none
  outline: none

.select
  font-size: 1rem
  padding: 16px 16px 16px 12px
  color: var(--primary-text-gray)
  -webkit-tap-highlight-color: transparent
  font-weight: normal
  width: 100%
  background-color: transparent
  line-height: 1.25
  touch-action: manipulation
  border: none
  outline: none
  margin-right: 16px
  cursor: pointer

.errorDescription
  font-size: 14px
  margin: 6px 0 0 0
  color: var(--red-alert)
