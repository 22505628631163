#departaments
  display: flex
  align-items: center
  justify-content: center
  font-family: var(--poppins)
  span
    text-align: center

.overlay
  width: 100vw
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  position: fixed
  top: 0px
  left: 0
  z-index: 2

.departamentContainer
  position: relative
  padding: 10px 0px
  border-top: 5px solid transparent

.departamentContainerActive
  position: relative
  padding: 10px 0px
  border-top: 2px solid var(--primary-colorgold)
  .departament
    color: var(--primary-colorgold)

.departament
  color: var(--white)
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  text-decoration: none
  font-weight: medium
  font-size: 12px
  position: relative
  box-sizing: border-box
  padding: 0px 10px
  cursor: default
  text-align: center !important
  font-family: var(--poppins)
  letter-spacing: 1.5px
  text-transform: uppercase
  font-weight: 400
  cursor: pointer
  &:hover
    font-family: var(--poppins)
    color: var(--primary-colorgold)
    border-radius: 100px

.families
  width: 220px
  position: absolute
  background-color: var(--white)
  box-sizing: border-box
  padding: 0px
  top: calc(100% + 1px)
  left: calc(50% - 105px)
  height: 0
  overflow: hidden
  display: flex
  flex-direction: column
  transition: padding-top 200ms, padding-bottom 200ms ease-out
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
  border-radius: 8px
  z-index: 2
  &.true
    padding: 10px
    height: auto
    overflow: visible
  .familyIcon
    padding-right: 8px
  .familyTitle
    display: flex
    align-items: center
    text-decoration: none
    color: var(--primary-colorblue)
    font-size: 12px
    font-weight: 500
    padding: 8px
    border-bottom: 1px solid var(--border-color-gray-light)

.phone
  align-items: center
  justify-content: center
  padding: 10px 0px 15px
  background-color: var(--primary-colorblue) !important
  padding-top: 0px !important
  display: block !important
  box-sizing: border-box
  width: 250px
  height: 100vh
  position: fixed
  top: 80px
  left: -250px
  transition: left 300ms ease-out
  overflow: auto
  z-index: 2
  font-family: var(--poppins)
  top: 0
  &.true
    left: 0
  .departamentContainer
    width: 100%
    height: 50px
    border-bottom: 1px solid var(--secondary-colordarkblue)
  .departamentContainerActive
    width: 100%
    height: 50px
    border-bottom: 1px solid var(--secondary-colordarkblue)
    border-top: 1px solid var(--secondary-colordarkblue)
  .departament
    padding: 0px 16px
    border: 0px
    font-size: 16px
    border-radius: 6px
    justify-content: flex-start
    color: var(--primary-text-gray)
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    &:hover
      font-family: var(--poppins)
      font-weight: 700
      border-radius: 130px
      color: var(--white)
      background-color: transparentize(rgba(#036, 0.3), 0.3)

  .families
    position: relative
    top: 0
    width: 100%
    border-radius: 0px
    border: 0px
    box-shadow: 0px 0px 0px 0px
    &.true
      border-top: 1px solid var(--border-color-gray-light)
      border-bottom: 1px solid var(--border-color-gray-light)

.divHidden
  display: hidden

.logo
  display: flex
  align-items: center
  justify-content: space-between
  width: 120px
  cursor: pointer
  img
    width: 100%
    margin-right: -75px

.button
  background-color: #9b722a
  color: #ffffff
  font-size: 14px
  border-radius: 100px
  padding: 8px
  font-family: var(--poppins)
  font-weight: 600
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  box-sizing: border-box
  margin: 14px
  margin-top: 35px
  img
    margin-right: 5px
    height: 14px
    width: auto

.language
  margin-top: 35px !important
  width: 50%
  margin: 16px

.space
  padding-top: 24px
