#Joinup
  &.phone
    .container
      margin-top: 10px
      display: table-cell
      flex-wrap: wrap
      align-content: space-between
      justify-content: center
      margin-bottom: 10px
      margin-left: 16px
      margin-right: 16px

      .city
        padding: 2px
        width: 160px
        height: 150px
        vertical-align: bottom
        border-radius: 50%
        margin-top: 15px !important
        margin-left: 10px

      .boxCity
        border-radius: 16px
        background-color: var(--primary-background)
        width: 90% !important
        margin-top: 20px
        
      .headerBox
        display: table-cell
        align-items: center
        font-family: var(--poppins)
        width: 1050px !important

      .button
        font-size: 18px

      .info
        color: var(--primary-text-gray)
        line-height: 25px
        font-size: 16px !important
        width: 100% !important
        margin-bottom: 20px

      .bloqueCity
        width: 100% !important
        align-items: center
        justify-content: center !important
        flex-direction: column !important
        padding-left: 20px !important
        display: flex
        margin-top: 25px

        .cityName
            font-size: 24px
        

  .container
    margin-top: 10px
    display: flex
    flex-wrap: wrap
    align-content: space-between
    justify-content: center
    margin-bottom: 10px
    font-family: var(--poppins)
    font-size: 1.2rem
    line-height: 30px

    .boxCity
      border-radius: 16px
      background-color: var(--primary-background)
      width: 40% !important
      margin-top: 20px
      margin-left: 20px
      
  .iframe
    width:95%
    height:250px
    margin-left: 10px
    margin-right: 10px
    margin-top: 10px

  .headerBox
    display: flex
    align-items: center
    font-family: var(--poppins)
    font-size: 14px

  .bodyBox
    margin-left: 10px
    margin-top: 15px
    width: 60% !important
    text-align: justify
    margin-right: 20px !important

  .bloqueCity
    width: 40% !important
    align-items: center
    justify-content: center !important
    flex-direction: column !important
    padding-left: 20px !important
    display: flex
    margin-top: 25px

    .city
      border: 2px solid rgba(150, 112, 10, 0.767)
      padding: 2px
      width: 100px
      height: 100px
      vertical-align: bottom
      border-radius: 50%
      margin-top: 15px !important
      margin-left: 10px

    .cityName
      font-family: var(--title-font)
      text-transform: uppercase 
      color: var(--primary-colorgold)
      font-weight: 600
      margin-top: 0px !important
      font-size: 20px

  .button
      margin-top: 10px
      margin-bottom: 5px !important
      font-weight: 500
      text-align: left !important
      color: var(--primary-colorblue) !important
      display: flex
      justify-content: left
      align-items: center
      height: 10px

  .icon
      color: var(--primary-colorgold)

  .info
      color: var(--primary-text-gray)
      line-height: 25px
