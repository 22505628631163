#Faq
  font-family: var(--poppins)
  width: 100% !important
  &.phone
    .Text
      display: table-cell
      width: 10% !important
        
    .boxCategory
      width: 99%
      height: 35%
      padding-left: 20px
      padding-right: 20px
      padding-bottom: 0px
      border-radius: 10px
      margin-top: 15px
      color: red !important
      margin-bottom: 65px
    
    .answer
      width: 95% !important
      margin-left: 2%
      margin-top: 40px !important

    .question 
      width:100% !important
      font-weight: 550
      font-size: 16px
    
    .resp
      display: block
      font-weight: 300 !important
      font-size: 16px
      line-height: 21px
      color: var(--primary-text-gray)
      margin-top: 10px
      width:100% !important
      padding: 0px

    .answerBox2
      color: var(--primary-colorblue)
      display: block
      font-size: 16px
      font-weight: bold
      cursor: pointer
      font-family: var(--poppins)
      border-bottom: 1px solid
      border-color: var(--primary-border-color-gray)
      width: 100% !important

.Text
  width: 98% !important
  text-align: justify
  margin-left: 2%
  margin-right: 2%
  display: flex
  margin-top: 15px
  font-size: 2px !important
  line-height: 20px
  color: var(--primary-text-gray) !important
  padding: 16px 16px
  
.boxCategory
  width: 18%
  height: 35%
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 40px
  border-radius: 10px
  margin-top: 65px
  color: var(--primary-text-gray) !important
  margin-bottom: 65px

.category
  cursor: pointer
  display: block
  font-size: 16px
  padding-top: 17px
  display: flex

.categoryUnderline
  cursor: pointer
  display: block
  font-size: 16px
  padding-top: 17px
  display: flex

.titleCategory
  color: var(--primary-colorgold)
  font-size: 18px
  font-weight: 600
  margin-left: 20px

.answer
  width: 75%
  margin-left: 2%
  margin-top: 90px

.openIcon
  font-size: 22px
  width: 3px !important
  margin-right: 5px

.answerBox2
  color: var(--primary-colorblue)
  display: block
  font-size: 16px
  font-weight: bold
  cursor: pointer
  font-family: var(--poppins)
  border-bottom: 1px solid
  border-color: var(--primary-border-color-gray)

.image
  height: 28px
  width: auto
  vertical-align: middle
  margin-right: 6px
  margin-left: 6px

.menuHeader
  margin-bottom: 20px
  display: flex !important
  align-items: center !important

.questionHeader
  margin-bottom: 20px
  display: flex !important
  align-items: center
  
.menuIcon
  color: var(--primary-colorgold)
  font-size: 26px
  width: 5% !important
  float: right !important

.none
  display: none

.question 
  width:98% !important
  font-weight: 550
  font-size: 16px

.resp
  display: block
  font-weight: 300 !important
  font-size: 16px
  line-height: 21px
  color: var(--primary-text-gray)
  margin-top: 10px
  width:100% !important
  padding: 0px

#Titles
    padding: 16px 32px
    font-family: var(--poppins)
    background-color: var(--primary-background)
    &.phone
        .text
            width: 100%
        .title
            font-size: 28px
        .subtitle
            font-size: 14px

.title
    padding: 0
    margin: 0
    color: var(--primary-colorblue)
    font-size: 32px
    font-weight: 500
    text-align: center
    font-family: var(--subtitle-font)
    margin-top: 10px

.subtitle
    color: var(--primary-colorgold)
    font-size: 30px
    margin: 0
    font-family: var(--title-font)
    text-align: center 
    margin-top: 5px
    font-weight: 450
    text-transform: uppercase

.subtitle2
    color: var(--primary-colorblue)
    font-size: 14px
    margin: 0
    font-family: var(--poppins)
    text-align: center 
    margin-top: 5px
    font-weight: 450

.categories
    padding: 5px
    border-radius: 10px
    color: var(--primary-text-gray)
    font-size: 14px
    box-sizing: border-box
    font-family: var(--poppins) !important

.category
    flex: 1
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    color: var(--primary-text-gray)
    padding: 8px 0px
    border-radius: 8px 8px 0px 0px
    transition: all 100ms ease-out
    font-size: 16px
    i
        color: var(--primary-text-gray)
        font-size: 16px
        transition: all 200ms ease-out
    span
        font-weight: 400
        font-size: 16px
    &.true
        color: var(--primary-colorblue)

.categoryUnderline
    flex: 1
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    color: var(--primary-colorblue)
    padding: 8px 0px
    border-radius: 8px 8px 0px 0px
    transition: all 100ms ease-out
    font-size: 16px
    font-weight: 550
    i
        color: var(--primary-colorblue)
        font-size: 16px
        transition: all 200ms ease-out
    span
        font-weight: 400
        font-size: 16px
    &.true
        color: var(--primary-colorblue)

.respBox
  margin-bottom: 15px
  margin-top: 10px
