.container
  padding: 16px
  
  .box
    background: var(--primary-background)
    text-align: justify
    border-radius: 30px
    font-family: var(--poppins) !important
    padding: 35px
    color: var( --primary-text-gray)
    font-weight: 400

  .Titles
    font-family: var(--poppins)
    background-color: var(--primary-background)

  .title
    color: var(--primary-colorgold)
    font-size: 28px
    font-family: var(--title-font)
    text-align: center 
    margin-top: 5px
    font-weight: 550
    text-transform: uppercase

  .subtitle
    color: var(--primary-colorblue)
    font-size: 28px
    font-weight: 500
    text-align: center
    font-family: var(--subtitle-font)
    margin-top: -3px

  .link
    color: var(--primary-colorgold) !important
    cursor: pointer
    text-decoration: underline !important
    display: table-row

  .paragraph
    width: 100%
    white-space: normal !important

  .paragraphTitle
    color: var(--primary-colorblue)
    font-weight: 500

  .paragraphTitleCenter
    color: var(--primary-colorblue)
    font-weight: 500
    font-size: 22
    text-align: center

  .list
    color: var(--primary-colorblue)
    font-weight: 500
    width: 100% !important
    margin-bottom: 10px
    text-align: left

  .span
    color: var(--primary-text-gray)
    font-weight: 400

  .tableIMG
    display: flex
    justify-content: center

  .img
    width: 100%
    max-width: 1200px

  .imagecenter
    display: flex
    justify-content: center

  .legalImage
    width: 100%
    max-width: 1200px

  .legalImage2
    width: 100%
    max-width: 1000px

  .legalParagraph
    margin: 8px 0px
