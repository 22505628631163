#Home
  &.phone
    .videoCard
      width: 80%
      height: 320px
    .adventCard
      width: 90%
      height: 500px

.videoContainer
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  z-index: 9
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

.overlay
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background-color: rgba($color: #000000, $alpha: 0.5)

.videoCard
  position: relative
  background-color: #ffffff
  width: 800px
  height: 500px
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center

.closeVideo
  color: #ffffff
  font-size: 28px
  position: absolute
  top: 20px
  right: 20px
  cursor: pointer

.sectionTitle
  width: 100%
  margin-top: 30px
  text-align: center
  margin-bottom: 24px
  padding-left: 35px
  padding-right: 35px

.titleText
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 32px

.subtitleText
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 30px
  margin-top: 5px
  font-weight: 500
  text-transform: uppercase

.adventCard
  position: relative
  background-color: #ffffff
  width: 100%
  max-width: 1000px
  height: calc( 100vh - 100px )
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
