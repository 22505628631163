@import url('https://fonts.googleapis.com/css?family=Raleway:400,700')

#Products
  font-family: 'Raleway', sans-serif
  &.tablet
    .depContainer
      width: 35%
    .loading
      width: 65%
    .loadingProductContainer
      width: 50%
  &.phone
    .container
      flex-direction: column
    .depContainer
      width: 100%
    .leftContainer, .loading, .loadingProductContainer
      width: 100%
    .loadingProductContainer
      padding: 0px 15px
    .productImage
      height: 130px
    .productName
      font-weight: 600
      font-size: 16px
    .menu
      width: 100%
      position: static
      height: auto
    .menuContainer
      height: auto
    .menuHeader
      cursor: pointer

#HeroBanner
  height: 300px
  background-image: url('https://webimages.terramarbrands.com.mx/webpage/products/banner-productos.jpg')
  background-position: right
  background-repeat: no-repeat
  background-size: cover

.container
  display: flex

.loading
  padding-top: 15px
  display: flex
  justify-content: center
  width: 75%

.depContainer
  width: 25%
  box-sizing: border-box
  padding: 15px

.productsContainer
  width: 78%
  display: flex
  flex-wrap: wrap

.menu
  width: 20%
  background-color: var(--white)
  position: -webkit-sticky
  position: sticky
  top: 80px
  height: calc(100vh - 80px)

.menuHeader
  padding: 24px 24px 8px 24px
  display: flex
  align-items: center

.menuIcon
  color: var(--primary-colorgold)
  font-size: 26px

.menuContainer
  height: calc( 100% - 50px )

.title
  color: var(--primary-colorgold)
  font-size: 18px
  font-weight: 600

.deptName
  font-weight: 600
  font-size: 20px
  padding: 8px
  cursor: pointer
  border-radius: 6px
  &:hover
    text-decoration: underline
    background-color: rgba(0,0,0,.5)
  &.true
    text-decoration: underline
    background-color: rgba(0,0,0,.5)

.subName
  font-size: 16px
  cursor: pointer
  padding: 5px 10px
  border-radius: 6px
  &:hover, &.true
    text-decoration: underline
    font-weight: 600
    background-color: rgba(0,0,0,.3)

.leftContainer
  width: 80%

.productsContainer
  padding-bottom: 15px
  width: 100%

.loadingProductContainer
  box-sizing: border-box
  width: 33.33%
  padding-right: 15px
  margin-top: 15px

.productContent
  background-color: var(--primary-background)
  box-sizing: border-box
  padding: 15px
  border-radius: 12px
  // cursor: pointer
  transition: all .2s ease-in-out
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  &:hover
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
    .productImage
      img
        transform: scale(1.15)
    .itemTitle
      text-decoration: underline

.productImage
  height: 160px
  img
    transition: all .2s ease-in-out
    height: 100%
    width: auto

.productName
  text-align: center
  font-weight: 500
  font-size: 14px
  padding: 10px 0px
  font-family: var(--poppins)
  color: var( --secondary-colordarkblue)
  width: 80%

.inblock
  padding: 40px

.textStyle
  padding-left: 70px
  .h1
    width: 55%
    font-size: 30px
    text-transform: uppercase
    font-weight: 300
    color: var(--primary-colorgold)
    font-family: var(--title-font)
    margin-top: 50px
  .h5
    width: 35%
    margin-top: 10px
    font-size: 16px
    font-weight: 600
    text-align: justify
    line-height: 22px
    font-family: var(--poppins)
    color: var(--primary-colorblue)
    margin-bottom: 80px

.productPrice
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  color: var(--color-gray)
  font-size: 20px

.pesitos
  font-size: 12px !important

.catalogPrice
  font-family: var(--poppins)
  font-weight: 400

.slider
  position: relative
