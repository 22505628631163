#Schools
  margin-top: 10px !important
  margin-bottom: 50px !important
  padding: 16px
  &.phone, &.tablet
    .bloqueSchool
      width: 100%
    .label
      width: auto
    .img
      width: 100%
    .divBlue
      width: 80%
    .bloqueSchoolPro
      width: 100%

.title
  font-weight: 700
  font-size: 22px
  text-align: center
  margin-top: 16px
  margin-bottom: 40px

.schools
  display: flex
  flex-wrap: wrap

.schoolsMobile
  display: columns !important
  align-items: center
  background-color: var(--primary-background)
  border-radius: 20px
  padding: 10px

.bloqueSchool
  padding: 10px 10px 10px 10px
  box-sizing: border-box
  display: flex
  width: 33.33%

.bloqueSchoolPro
  padding: 10px 10px 10px 10px
  width: 33.333%
  box-sizing: border-box
  display: flex

.divBlue
  background: var(--primary-background)
  border-top-left-radius: 15px
  border-bottom-left-radius: 15px
  padding: 5px

.divGray
  background: var(--primary-background)
  width: 100%
  border-top-left-radius: 15px
  border-bottom-left-radius: 15px
  border-top-right-radius: 15px
  border-bottom-right-radius: 15px

.divImage
  background: var(--primary-background)
  border-top-right-radius: 15px
  border-bottom-right-radius: 15px
  height: 100%

.divImageMobile
  width: 99%
  cursor: pointer
  padding: 15px 7px

.imgMobile
  height: 160px
  width: 100%
  margin-top: 10px
  object-fit: cover

.img
  height: 270px
  width: 270px
  object-fit: cover
  border-top-right-radius: 15px
  border-bottom-right-radius: 15px

.image
  height: 250px
  margin: 50px 30px 30px 30px
  color: red
  background-color: red

.labelGray
  color: white
  font-weight: 700
  font-size: 20px
  display: flex
  width: 100% !important
  height: 100%
  align-items: center
  align-content: center
  text-align: center !important
  justify-content: center
  color: #aba6a6
  letter-spacing: 1px

.container
  width: 100%
  color: white
  align-items: center
  justify-content: center

.subContainer
  display: flex !important
  background: #002747
  align-items: center !important
  align-content: center !important
  justify-content: center !important
  opacity: .90

.modal
  background: #002747
  opacity: .90

.modalDialog
  background: #002747
  opacity: .90
  height: 550px

.menuImages
  float: left

.images
  background: pink
  margin-bottom: -10px !important

.imageCarrousel
  height: 50px
  width: 70px
  opacity: 1 !important

.imageSlider
  height: 485px
  width: 1250px

.imageSliderMobile
  height: 485px
  width: 700px

.buttonClose
  color: white !important
  font-weight: 700 !important
  cursor: pointer

.labelBlack
  color: var(--primary-colorblue)
  font-weight: 700
  font-size: 18px

.label
  font-size: 14px
  width: 226px
  color: var(--primary-colorblue)
  font-weight: 700
  padding: 10px

.descText
  color: var(--primary-colorblue)
  margin-left: 10px
  margin-top: 1px !important
  font-weight: 300
  font-size: 12px
  margin-bottom: 18px

.button
  margin-top: 10px
  margin-bottom: 14px !important
  font-weight: 500
  text-align: left !important
  color: var(--primary-colorblue)
  display: flex !important
  justify-content: left
  align-items: center
  height: 10px
  font-size: 12px

.icon
  color: var(--primary-colorgold)
  font-size: 18px
  padding-left: 8px

.SubtitleBlue
  color: var(--primary-colorblue)
  font-family: var(--subtitle-font)

.SubtitleGold
  color: var(--primary-colorgold)
  font-family: var(--title-font)
  text-transform: uppercase
