.menuItem
  display: flex
  flex-direction: column
  font-family: var(--poppins)
  &.phone
    .item
      span
        font-size: 15px
    .subitems
      font-size: 15px

.item
  flex: 1
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer
  color: var(--primary-text-gray)
  padding: 8px 0px
  border-radius: 8px 8px 0px 0px
  transition: all 100ms ease-out
  i
    color: var(--primary-text-gray)
    font-size: 18px
    transition: all 200ms ease-out
  span
    font-weight: 400
    font-size: 16px
  &.true
    color: var(--primary-colorblue)
    i
      transform: rotate(90deg)
      color: var(--primary-colorgold)

.subitems
  color: var(--primary-colorblue)
  background-color: var(--white)
  overflow: hidden
  transition: padding 200ms ease-out
  height: 0px
  padding: 0px 8px
  border-radius: 0px 0px 8px 8px
  border: 0px
  &.true
    height: auto
    padding: 8px
    border-top: 1px solid var(--primary-colorgray)
    padding-bottom: 8px

.subitem
  display: flex
  align-items: center
  padding: 4px
  &:hover
    background-color: var(--primary-colorgray)
    cursor: pointer
  &.true
    background-color: var(--primary-colorgray)

.sectionImage
  width: 25px
  height: 25px
  min-width: 25px
  overflow: hidden
  border-radius: 50%
  border: 1px solid var(--color-border-gray)
  background-size: cover
  background-position: 50%

.sectionName
  padding-left: 8px
