.Banner
  background-color: var(--primary-colorgold)

.slideContainer
  position: relative
  img
    width: 100%
    height: auto

.goTo
  width: 100%
  height: 60px
  background-color: rgba(255, 255, 255, 0.5)
  position: absolute
  bottom: 0px
  box-sizing: border-box
  padding: 0px 20px
  display: flex
  align-items: center
  font-size: 16px
  font-family: var(--poppins)
  font-weight: 500
  a
    background-color: var(--primary-colorgold)
    padding: 4px 16px 6px 16px
    border-radius: 8px
    color: #ffffff
    text-decoration: none
    display: flex
    justify-content: center
    align-items: center
    &:hover
      text-decoration: underline
    i
      padding-left: 4px
