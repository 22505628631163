#Catalog
    &.tablet
        .document
            width: 50%
    &.phone
        .document
            width: 100%
            img
                height: 300px

.documentsContainer
    display: flex
    flex-wrap: wrap
    width: 100%
    justify-content: center !important
    align-items: center !important
    padding-left: 30px
    padding-right: 30px

.document
    box-sizing: border-box
    justify-content: center
    align-items: center
    display: flex
    flex-direction: column
    margin: 25px 25px
    background: linear-gradient(0.50turn, var(--primary-colorblue) 15%, var(--primary-background) 50%)
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    width: 250px
    img
        width: 100%
        height: 280px
        margin-top: 20px
        object-fit: cover

.button
    margin-top: 15px
    margin-bottom: 15px !important
    font-weight: 600
    text-align: center
    background-color: var(--primary-colorblue)
    border-radius: 100px
    color: var(--white)
    padding: 10px
    width: 200px
    cursor: pointer
    color: #ffffff
    text-decoration: none
    display: flex
    justify-content: center
    align-items: center
    &:hover
        background-color: var(--primary-colorgold)

.label
    text-transform: uppercase
    color: var(--primary-colorblue)
    font-weight: 600
    margin-top: 20px !important
    margin-bottom: -5px
    color: var(--white)

.buttonText
    text-align: center !important
