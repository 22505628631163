#CategoriesCarousel
  position: relative
  display: flex
  flex-direction: column
  padding: 0px 8px
  &.phone, &.tablet
    .sliderContainer
      width: 100%
      .slider
        width: 100%
        .productName
          width: 200px
  &.phone
    .arrow
      position: relative
      margin: 0px 8px
      background-color: rgba(0,39,71,0)
    .arrowIcon
      font-size: 40px
      color: var(--primary-text-gray)

.container
  display: flex
  position: relative

.sliderContainer
  width: 100%

.arrow
  position: absolute
  width: 50px
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  cursor: pointer
  background-color: rgba(0,39,71,.1)
  top: 0px
  bottom: 0px
  transition: all 0.15s ease-in-out
  &:hover
    background-color: rgba(0,39,71,.4)
    .arrowIcon
      transform: scale(1.2)

.leftArrow
  left: 0px

.rightArrow
  right: 0px

.arrowIcon
  font-size: 32px
  color: var(--white)
  transition: all 0.15s ease-in-out

.slider
  width: 100%

.slideContent
  position: relative
  background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0.3)60%, rgba(0,0,0,0) 100%)
  padding: 16px

.productName
  padding: 20px 5px 0px 5px
  font-size: 14px
  text-align: center
  width: 100%
  box-sizing: border-box
  color: var(--primary-colorblue)
  font-weight: 500

.category
  padding: 0px 8px

.slideContainer
  background-color: var(--primary-background)
  height: 300px
  position: relative
  display: flex
  flex-direction: column-reverse
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  overflow: hidden

.bgImage
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  transition: all 0.2s ease-in-out
  cursor: pointer
  &:hover
    opacity: 0.7
    transform: scale(1.05)

.categoryName
  color: var(--white)
  font-family: var(--title-font-medium)
  font-size: 22px

.mobileArrows
  margin-top: 16px
  display: flex
  width: 100%
  justify-content: center

.titleContainer
  width: 100%
  text-align: center
  margin-bottom: 16px

.title
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 28px

.subtitle
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 28px
