.Footer
  width: 100%
  display: flex
  justify-content: space-between
  background-color: var(--primary-background)
  color: var(--primary-text-gray)
  padding: 16px 32px
  font-family: var(--poppins)
  font-size: 14px
  &.phone
    flex-direction: column
    align-items: left
    height: 100px !important
    .left
      flex-direction: column
      align-items: left
      height: 100px !important

.FooterMobile
  width: 100%
  display: flex
  justify-content: space-between
  background-color: var(--primary-background)
  color: var(--primary-text-gray)
  padding: 16px 32px
  font-family: var(--poppins)
  font-size: 14px
  align-items: left
  &.phone
    flex-direction: column
    align-items: left
    .left
      flex-direction: column
      align-items: left

.left
  color: var(--primary-text-gray)
  margin-top: 5px
  &.phone
    width: 100%
    .left
      height: 90px

.brandName
  color: var(--primary-colorblue)
  padding-right: 8px
  font-weight: 600

.right
  font-weight: 600

.logo
  display: flex
  align-items: center
  justify-content: space-between
  width: 150px
  img
    width: 100%
    margin-right: -75px

.subMenu
  margin-top: 10px

.menuWhitIcon
  display: flex
  align-items: center

.menuIcon
  padding-right: 5px
  font-size: 20px

.link
  cursor: pointer
  margin-bottom: 4px
  &:hover
    text-decoration: underline

a
  color: var(--primary-text-gray)
  display: flex
  align-content: center
  text-decoration: none !important

.linkRedirect
  text-decoration: underline
  cursor: pointer
  &:hover
    text-decoration: underline

.secondBrand
  margin-top: 16px

.secondSubMenu
  margin-bottom: 8px

.dsaLogo
  width: 50px

.dsaLogo
  margin-top: -15px
