.lastFooter
  width: 100%
  display: flex
  background-color: var(--primary-colorblue)
  color: var(--white)
  padding: 16px 32px
  font-family: var(--poppins)
  font-size: 14px
  z-index: 9999999
  &.phone
    flex-direction: column
    .left
      flex-direction: column
    .link
      padding: 7px
      text-decoration: underline
      cursor: pointer
      text-align: center
      font-size: 12px

.left
  display: flex
  align-items: center
  color: var(--primary-text-gray)

.brandName
  color: var(--white)
  padding-right: 8px

.brandNameMobile
  color: var(--primary-text-gray)
  padding-right: 8px
  font-weight: 500
  font-size: 12px
  width: 60%
  text-align: center
  margin-top: 10px
  margin-bottom: 20px

.brandNameSecurity
  padding-right: 8px
  color: var(--primary-text-gray)
  font-weight: 600
  width: 75%
  align-content: center
  text-align: center
  font-size: 12px

.right
  font-weight: 600
  display: flex
  align-content: center
  padding-left: 45px
  align-items: center

.rightMobile
  font-weight: 600
  display: flex
  align-content: center
  align-items: center

.image
  padding-left: 10px
  width: 35px

.span
  color: var(--white)
  font-weight: 600

.terms
  display: flex
  align-content: center
  margin-top: 10px
  margin-bottom: 30px !important

.termsUSA
  align-content: center
  margin-top: -25px
  margin-bottom: 20px 
  text-align: center

.link
  padding: 10px
  text-decoration: underline
  cursor: pointer

.flex
  display: flex
