#CreandoSonrisas
  font-family: var(--poppins)
  .banner
    width: auto
    height: 350px
    color: #ffffff
    display: flex
    background-image: url("https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/creando-sonrisas.jpg")
    background-repeat: no-repeat
    background-size: 100%
    align-items: center

  .bannerText
    padding: 10px 10px 10px 10px

  .title
    font-weight: 700
    font-size: 20px

  .titleSmiles
    margin-left: 85px

  .text
    width: 35%
    font-size: 14px
    margin-left: 85px
    margin-top: 0px
    color: var(--primary-colorblue)

  .secondBloque
    display: flex
    align-items: center
    width: auto
    height: 450px
    margin-left: 150px
    margin-top: 80px !important

  .secondBloqueMobile
    display: flex
    align-items: center
    width: 95% !important
    height: 790px !important
    margin-left: 0px
    margin-top: 350px

  .img
    width: 100%
    margin-left: 50px !important

  .imgMobile
    width: 80%
    margin-left: 50px

  .subBloque
    height: 450px
    margin-left: 100px

  .subBloqueMobile
    margin-left: 5px

  .imageMobileDiv
    width: 99% !important

  .title
    margin-left: 50px
    font-size: 22px
    font-weight: 700

  .subTitle
    margin-left: 50px
    font-size: 13px
    width: 450px
    margin-top: 7px
    color: var(--primary-text-gray)
    line-height: 18px

  .subTitleMobile
    margin-top: 10px
    align-content: center
    align-items: center
    width: 99%
    font-size: 14px
    text-align: justify
    padding-left: 20px !important
    padding-right: 0px !important
    color: var(--primary-text-gray)
    font-weight: 400

  .options
    display: flex
    align-content: center
    align-items: center
    margin-left: 100px
    margin-top: 20px

  .optionsMobile
    display: flex
    align-content: center
    align-items: center
    margin-left: 70px
    margin-top: 20px

  .infoOption
    margin-left: 15px
    font-weight: 600
    font-size: 14px !important
    color: var(--primary-colorblue)

  .textInfoOption
    margin-left: 15px
    margin-top: 0px
    font-size: 13px
    width: 350px
    color: var(--primary-text-gray)
    line-height: 17px

  .textInfoOptionMobile
    margin-left: 15px
    margin-top: 0px
    font-size: 14px
    width: 80%
    color: var(--primary-text-gray)
    line-height: 17px

  .imageBanner
    width: 99% !important
    height: 135px !important

  .image
    width: 100%

  .titleMobile
    width: 100%
    border: 1px
    text-align: center
    color: var(--primary-colorblue)
    font-size: 28px
    font-family: var(--subtitle-font) !important

  .titleMobileSecondBloque
    width: 100%
    border: 1px
    text-align: left
    color: var(--primary-colorblue)
    font-size: 16px
    font-family: var(--poppins) !important
    padding-left: 20px
    font-weight: 500

  .subTitleGoldMobile
    color: var(--primary-colorgold)
    width: 100%
    border: 1px
    font-size: 22px
    text-align: center
    font-weight: 600
    margin-left: -5px
    font-family: var(--title-font)

  .textMobile
    margin-top: 2px
    align-content: center
    align-items: center
    width: 95%
    font-size: 14px
    text-align: center
    padding-left: 20px !important
    padding-right: 20px !important
    color: var(--primary-colorblue)
    font-weight: 500

  .colorDiv
    background-color: #f5f3f4
    height: 340px
    width: 99% !important

  .titleBlue
    font-family: var(--subtitle-font)
    font-size: 24px
    font-weight: 550
    color: var(--primary-colorblue)

  .titleGold
    font-family: var(--title-font)
    color: var(--primary-colorgold)
    text-transform: uppercase
    font-size: 24px
    font-weight: 600

  .titleBlueBloque
    font-family: var(--subtitle-font)
    font-size: 24px
    font-weight: 550
    color: var(--primary-colorblue)

  .titleGoldBloque
    font-family: var(--title-font)
    color: var(--primary-colorgold)
    text-transform: uppercase
    font-size: 24px
    font-weight: 600

  .SubtitleBlue
    font-family: var(--subtitle-font)
    font-size: 22px
    font-weight: 600
    color: var(--primary-colorblue)

  .SubtitleGold
    font-family: var(--title-font)
    color: var(--primary-colorgold)
    text-transform: uppercase
    font-size: 22px
    font-weight: 600

  .bannerFondo
    width: auto
    display: flex
    height: 350px
    margin: 50px 30px 30px 30px
    border-radius: 10px
    background-image: url("https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/recaudado-creando.jpg")
    background-repeat: no-repeat
    background-size: 100%
    align-items: center

  .bannerFondoMobile
    width: auto
    height: 620px
    margin: 50px 30px 30px 30px
    border-radius: 10px
    color: #ffffff
    background-image: url("https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/creando-sonrisas-mobile.png")
    background-repeat: no-repeat
    background-size: 100%
    align-items: center

  .imageRecaudadoMobile
    margin-right: 40px !important
    margin-top: 15px
    width: 65px

  .imageRecaudado
    margin-top: 20px !important
    margin-right: 20px
    width: 120px

  .meta
    color: var(--primary-colorgold)
    font-size: 24px
    margin-top: 1px
    margin-left: 35px

  .metaMobile
    color: var(--primary-colorgold)
    font-size: 22px
    margin-top: 10px
    margin-left: 35px

  .metaNumber
    font-size: 28px
    color: var(--primary-colorgold)
    font-weight: 700
    margin-top: 0px
    margin-left: 35px

  .recaudado
    color: var(--primary-colorblue)
    font-size: 22px
    margin-top: 30px
    margin-left: 35px

  .recaudadoNumber
    font-size: 28px
    color: var(--primary-colorblue)
    font-weight: 700
    margin-top: 0px
    margin-left: 35px

  .imgRecaudadoMobile
    margin-left: 50px
    margin-top: 0px
    margin-bottom: 100px

  .imgRecaudado
    text-align: right
