@import url('https://fonts.googleapis.com/css?family=Raleway:400,700')

#RegistrationForm
  font-family: 'Raleway', sans-serif
  display: flex
  position: relative
  margin-left: 10px
  margin-right: 10px
  width: 100%

.container
  width: 99%
  margin-left: 10px
  margin-right: 10px

.focusError
    border-color: rgb(196, 27, 27) !important
    box-shadow: 01px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px rgba(247, 142, 5, 0.733) !important
    outline: 0 none !important
  

input
  border-radius: 5px !important
  width: 100%
  padding: 12px 20px
  margin: 8px 0
  box-sizing: border-box
  border: 1px
  border-style: solid

h5
  font-size: 20px
  font-family: 'Raleway', sans-serif

.personalInformation 
  display: flex
  .subItem 
    width: 35%
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 20px
    
  

.itemKit 
  width: 31%
  margin-left: 10px
  margin-right: 10px
  margin-bottom: 20px


.address 
  display: flex
  .subItem 
    width: 35%
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 20px
  


.contact 
  display: flex
  .subItem 
    width: 35%
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 20px

.select 
  display: block
  border-radius: 5px !important
  width: 100%
  padding: 12px 20px
  margin: 8px 0
  box-sizing: border-box
  border: 1px
  border-style: solid

.loading
  margin-top: 10px
  align-items: center
  font-size: 12px
  color: #002747
  text-align: center

.buttonsContainer
  margin-top: 10px
  text-align: center

.button
  font-size: 30px
  width: 200px
  background-color: #002747 !important

.buttonDisabled
  font-size: 30px
  width: 200px
  background-color: #cad0d4 !important

.successRegister
  font-family: 'Raleway', sans-serif
  margin-left: 20px
  margin-right: 20px
  width: 100%
  font-size: 24px
  margin-bottom: 80px
  margin-top: 90px
  text-align: center

@media only screen and (max-width: 1000px) 

  .personalInformation 
    display: block
    .subItem 
      width: 95% !important
      margin-left: 10px
      margin-right: 10px
      margin-bottom: 20px

  .itemKit 
    width: 50%
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 20px
    
  .address 
    display: block
    .subItem 
      width: 95% !important
      margin-left: 10px
      margin-right: 10px
      margin-bottom: 20px

  .contact 
    display: block
    .subItem 
      width: 95% !important
      margin-left: 10px
      margin-right: 10px
      margin-bottom: 20px

.return
  align-items: center
  text-align: left
  margin-left: 10px
  
.buttonsReturn
  align-items: center
  margin-top: 10px

.labelReturn
  text-decoration: underline
  cursor: pointer
  font-size: 18px
  &:hover
    color: #002747 !important
    
