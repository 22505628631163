#Loadingpage
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-image: linear-gradient(#002747,#01192a)
    color: #ffffff

.logo
    margin-bottom: 20px