#Joinup
  font-family: var(--poppins) !important
  &.phone
    .container
      padding: 16px 16px
    .optionsContainer
      flex-direction: column
    .findLeader, .contactForm, .option
      width: 100%
      margin-bottom: 20px !important
    .forms
      flex-direction: column
    .findLeader
      padding: 0px 0px 40px 0px
    .contactForm
      padding: 0px
    .promo
      flex-direction: column
      padding: 0px
      &.right
        flex-direction: column-reverse
    .promoLeft
      padding: 16px
    .promoRight
      padding: 16px
    .promoTextContainer
      width: 100%
    .promoImageContainer
      width: 100%
    .cursiveBlue
      font-size: 32px
    .capitalBlue
      font-size: 32px
    .cursiveGold
      font-size: 32px
    .capitalGold
      font-size: 32px
    .descriptionText
      font-size: 14px
    .promoTitle
      font-size: 24px
      margin-bottom: 16px
    .promoSubtitle
      font-size: 24px

.container
  padding: 16px 16px

.headerTitle
  font-weight: 700
  color: var(--primary-colorgold)
  font-size: 16px
  text-align: center
  font-family: var(--poppins)
  margin-top: 0px

.optionsContainer
  display: flex
  margin-top: 20px
  justify-content: center

.option
  width: 33.33%
  padding: 25px 20px 20px 20px
  box-sizing: border-box
  border-radius: 10px
  margin-inline-end: 10px
  background: linear-gradient(0.50turn, var(--primary-colorblue) 12%, var(--primary-background) 12%)

.image
  text-align: center
  height: 200px
  margin-top: 40px
  img
    height: 100%
    width: auto

.titleOption
  font-weight: 500
  font-size: 14px
  padding: 10px 0px
  margin-top: 30px
  color: var(--primary-colorblue)

.text
  font-size: 14px
  line-height: 20px
  color: var(--primary-text-gray)

.legal
  text-align: center
  font-size: 10px
  line-height: 15px
  color: var(--primary-text-gray)
  margin-top: 15px !important

.forms
  display: flex
  flex-wrap: nowrap
  margin-top: 40px

.findLeader, .contactForm
  width: 50%
  box-sizing: border-box

.findLeader
  padding: 0px 40px 0px 0px

.contactForm
  padding: 0px 0px 0px 40px

.leaderContainer
  padding: 15px

.formContainer
  border-radius: 10px
  color: var(--white)
  padding: 15px

.formContainerLeader
  background-color: var(--primary-background)
  border-radius: 10px
  color: var(--white)
  padding: 15px

.inputContainer
  margin-bottom: 15px

.button
  font-size: 30px
  width: 180px
  border-radius: 100px !important
  text-transform: capitalize !important
  background-color: var(--primary-colorblue) !important
  &.messageButton
    background-color: var(--primary-colorgold) !important
    margin-top: 20px
  &:hover
    background-color: var(--primary-colorgold) !important

.leadersTitle
  text-align: center
  font-size: 30px
  font-weight: 500
  color: var(--primary-colorgold)
  margin-bottom: -3px
  font-family: var(--title-font)
  text-transform: uppercase !important

.leadersSubTitle
  margin-top: 1px
  color: var(--primary-colorblue)
  text-align: center
  font-size: 15px
  font-weight: 500
  margin-bottom: 25px

.buttonsContainer
  margin-bottom: 15px
  display: flex

.leadersList
  margin-top: 30px
  border-top: 1px solid #cccccc

.resultstTitle
  font-size: 18px
  font-weight: 700
  margin: 15px 7px
  color: var(--primary-colorblue)

.leader
  background-color: var(--white)
  vertical-align: middle
  font-size: 1rem
  padding: 10px
  border: 1px solid rgba(0,0,0,.2)
  border-bottom: 0px
  display: flex
  justify-content: space-between
  align-items: center

.leaderButton
  padding: 7px
  border: 1px solid var(--primary-colorblue)
  color: var(--white)
  font-weight: 500
  border-radius: 6px
  background-color: var(--primary-colorblue)
  font-size: 14px
  cursor: pointer
  &:hover
    background-color: var(--primary-colorgold)
    color: var(--white)

.contactTitle
  font-size: 40px
  text-align: center
  margin-bottom: 10px

.contactSubtitle
  font-size: 20px
  text-align: center
  margin-bottom: 10px

.contactInput
  margin-top: 30px !important

.leaderSelected
  background-color: var(--primary-colorgold)
  padding: 15px 10px
  font-weight: 600
  margin-top: 25px

#Titles
  padding: 16px 32px
  font-family: 'Raleway', sans-serif
  background-color: var(--primary-background)
  background: linear-gradient( #F7F7F7 , #F6EFE6 50%)
  &.phone
    .text
      width: 100%
      .title
        font-size: 28px
        .subtitle
          font-size: 14px

.title
  padding: 0
  margin: 0
  color: var(--primary-colorblue)
  font-size: 32px
  font-weight: 500
  text-align: center
  font-family: var(--subtitle-font)
  margin-top: 10px

.subtitle
  color: var(--primary-colorgold)
  font-size: 30px
  margin: 0
  font-family: var(--title-font)
  text-align: center
  margin-top: 5px
  font-weight: 450
  text-transform: uppercase

.subtitle2
  color: var(--primary-colorblue)
  font-size: 14px
  margin: 0
  font-family: var(--poppins)
  text-align: center
  margin-top: 5px
  font-weight: 500

.areaContainer
  display: flex
  flex-wrap: wrap
  width: 100%

.legalLink
  color: var(--primary-colorgold)
  cursor: pointer

.agreeContainer
  color: var(--primary-text-gray)
  width: 90%
  display: flex
  justify-content: space-between
  margin-top: 0px
  font-size: 12px
  text-align: left !important
  align-items: center !important
  margin-bottom: 10px !important

.check
  color: var(--primary-colorgold) !important

.terms
  width: 100% !important

.leaderName
  color: var(--primary-text-gray)
  font-weight: 500
  font-size: 14px

.label
  text-transform: uppercase
  color: var(--primary-colorblue)
  font-weight: 600
  font-size: 14px
  margin-top: -10px !important
  margin-bottom: 5px
  color: var(--white)
  text-align: center

.buttonRegisterContainer
  display: flex
  justify-content: center

.capitalGold
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 40px

.promo
  display: flex
  align-items: center
  padding: 16px

.promoLeft
  padding-left: 60px
  padding-right: 60px

.promoRight
  padding-left: 60px
  padding-right: 60px

.promoTextContainer
  width: 55%

.promoImageContainer
  width: 45%

.promoImage
  width: 100%

.cursiveBlue
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 40px

.capitalBlue
  font-family: var(--title-font)
  color: var(--primary-colorblue)
  font-size: 40px

.cursiveGold
  font-family: var(--subtitle-font)
  color: var(--primary-colorgold)
  font-size: 40px

.capitalGold
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 40px

.descriptionText
  font-family: var(--poppins)
  color: var(--primary-colorblue)

.recaptchaText
  color: var(--primary-text-gray)
  font-size: 10px
