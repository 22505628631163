.tableContainer
  overflow: auto
  position: relative
  border: 1px solid rgba(0,0,0,.3)
  border-radius: 10px
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
  &.tableBranch
    font-size: 14px
    margin-top: 10px
  &.phone
    .cell
      padding: 8px

.principalTable
  background-color: rgba(0,0,0,.03)

.table
  display: inline-block !important
  min-width: 100%

.row
  display: flex
  flex-wrap: nowrap
  font-size: 13px
  width: 100% !important
  img
    width: 70px

.cell
  width: 100% !important
  display: flex
  justify-content: center
  align-items: center
  padding: 14px 8px
  text-align: center
  border-right: 1px solid #eee
  a
    color: #000000
    font-size: 14px

.headerCell
  padding: 4px 8px
  font-size: 12px

.tableHeader
  background: #eee !important
  font-weight: 600
  font-size: 13px

.callback
  text-decoration: underline
  cursor: pointer

.list
  color: var(--primary-colorblue)
  font-weight: 500
  width: 100% !important
  margin-bottom: 10px
  text-align: left

.span
  color: var(--primary-text-gray)
  font-weight: 400
