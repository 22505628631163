.BannerSide
  position: relative
  &.tablet
    .mainContainer
      padding: 16px 40px
    .title
      font-size: 16px
    .subtitle1, .subtitle2
      font-size: 22px
    .text
      font-size: 14px
  &.phone
    .mainContainer
      flex-direction: column
      padding: 0px
    .imageContainer
      width: 100%
    .textContainer
      width: 100%
      text-align: center
      padding: 16px
    .subtitle1, .subtitle2
      font-size: 22px
    .text
      font-size: 12px
    .seeMore
      text-align: center
      margin: 0 auto

.mainContainer
  padding: 24px 80px
  width: 100%
  display: flex

.textContainer
  width: 50%
  display: flex
  flex-direction: column
  justify-content: center
  padding-right: 16px
  padding-top: 16px
  padding-bottom: 16px

.imageContainer
  width: 50%
  min-height: 400px
  background-color: var(--primary-background)
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  display: flex
  flex-direction: column-reverse

.title
  font-family: var(--title-font-medium)
  color: var(--primary-colorgold)
  text-decoration: underline
  margin-bottom: 24px

.subtitle1
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 32px

.subtitle2
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 32px

.text
  color: var(--primary-colorblue)
  margin: 24px 0px
  font-size: 14px

.text2
  margin-top: 0px

.seeMore
  color: var(--primary-colorblue)
  text-decoration: underline
  font-size: 14px
  cursor: pointer
  width: 100px

.imageText
  width: 100%
  color: var(--white)

.imageTextContainer
  width: 100%
  padding: 16px
  background: rgb(0,0,0)
  background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0.3)60%, rgba(0,0,0,0) 100%)

.ImageTitle
  font-family: var(--title-font-medium)
  font-size: 16px

.imageText
  font-size: 12px
