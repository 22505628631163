#HeroBanner
    img
        width: 100%
        display: block

.sectionTitle
  width: 100%
  margin-top: 30px
  text-align: center
  margin-bottom: 24px
  padding-left: 35px
  padding-right: 35px

.titleText
    font-family: var(--subtitle-font)
    color: var(--primary-colorblue)
    font-size: 32px

.subtitleText
    font-family: var(--title-font)
    color: var(--primary-colorgold)
    font-size: 30px
    margin-top: 5px
    font-weight: 500
    text-transform: uppercase    
  