#About
  &.phone
    .foundersProfileContainer
      flex-direction: column
    .founderProfile
      margin-bottom: 16px

.foundersContainer
  background-color: var(--cream-color-background)
  padding: 32px

.foundersTitlesContainer
  width: 100%
  text-align: center

.foundersTop
  font-family: var(--title-font-medium)
  color: var(--primary-colorgold)
  text-decoration: underline

.foundersTitle
  font-family: var(--subtitle-font)
  color: var(--primary-colorblue)
  font-size: 32px
  margin-top: 24px

.foundersSubtitle
  font-family: var(--title-font)
  color: var(--primary-colorgold)
  font-size: 32px
  margin-bottom: 16px

.foundersProfileContainer
  width: 100%
  display: flex

.founderProfile
  padding: 0px 8px
  margin-top: 66px
  flex: 1

.fouderCard
  display: flex
  flex-direction: column
  align-items: center
  border: 1px solid var(--primary-colorgold)
  height: 100%
  padding: 16px
  text-align: center
  border-radius: 24px

.founderPicture
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  width: 100px
  height: 100px
  border-radius: 50px
  margin: -66px 0px 24px

.founderName
  color: var(--primary-colorblue)
  font-size: 20px
  font-weight: 500
  margin-bottom: 16px

.founderHistory
  color: var(--primary-text-gray)
  font-size: 14px
