@import url('https://fonts.googleapis.com/css?family=Raleway:400,700')

#Titles
    padding: 16px 32px
    font-family: 'Raleway', sans-serif
    background-color: var(--primary-background)
    &.phone
        .text
            width: 100%
        .title
            font-size: 28px
        .subtitle
            font-size: 14px

.title
    padding: 0
    margin: 0
    color: var(--primary-colorgold)
    font-size: 32px
    font-weight: 500
    text-align: center
    text-transform: uppercase
    font-family: var(--title-font)
    margin-top: 10px

.subtitle
    color: var(--primary-colorblue)
    font-size: 14px
    margin: 0
    font-family: var(--poppins)
    text-align: center 
    margin-top: 5px
    font-weight: 600
