.languageSelect
  color: var(--primary-text-gray)
  position: relative
  height: 32px
  &:hover
    color: var(--white)
    .languageSelected
      border-color: var(--white)

.languageSelected
  border: 1px solid var(--primary-text-gray)
  border-radius: 16px
  cursor: pointer
  padding: 6px 12px
  text-align: center
  font-size: 14px
  display: flex
  align-items: center
  justify-content: space-between
  transition: all 150ms ease-out
  &.true
    border-radius: 16px 16px 0px 0px

.icon
  font-size: 16px
  padding-left: 4px

.optionsContainer
  padding-left: 20px
  position: relative

.optionsContent
  position: absolute
  background-color: #ffffff
  box-sizing: border-box
  width: 100%
  padding: 0px
  top: 100%
  left: 0
  height: 0
  overflow: hidden
  display: flex
  flex-direction: column
  transition: all 200ms ease-out
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
  border-radius: 0px 0px 16px 16px
  z-index: 2
  &.true
    padding: 10px 0px
    height: auto
  .languageOption
    text-decoration: none
    color: #000000
    font-size: 14px
    padding: 20px 0px
    display: flex
    justify-content: center
    cursor: pointer
    &:hover
      background-color: #f5f5f5
