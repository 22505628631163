#RecommendedCarousel
    position: relative
    padding: 24px 8px
    &.phone, &.tablet
        .sliderContainer
            width: 100%
        .slider
            width: 100%
        .productName
            width: 200px

.container
    display: flex

.sliderContainer
    width: 100%

.arrow
    position: absolute
    width: 50px
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    left: 0px
    top: 0px
    img
        cursor: pointer

.arrowLast
    position: absolute
    width: 50px
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    right: 0px
    top: 0px
    img
        cursor: pointer

.slider
    width: 100%

.slideContent
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100%
    img
        height: 150px

.productName
    padding: 20px 5px 0px 5px
    font-size: 14px
    text-align: center
    width: 100%
    box-sizing: border-box
    color: var(--primary-colorblue)
    font-weight: 500

.link
    padding: 0px 8px

.slideContainer
    background-color: var(--primary-background)
    padding: 24px 8px
    border-radius: 8px
    height: 300px
