.orderTopHeader
  background-color: var(--primary-colorblue)
  display: flex
  padding: 8px 24px
  justify-content: space-between
  align-items: center
  height: 80px
  box-sizing: border-box
  position: relative
  position: -webkit-sticky
  z-index: 9

.logo
  display: flex
  align-items: center
  justify-content: space-between
  width: 120px
  img
    width: 100%
    margin-right: -75px
