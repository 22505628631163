@import url('https://fonts.googleapis.com/css?family=Raleway:400,700')

.productContainer
  box-sizing: border-box
  width: 33.33%
  padding-right: 15px
  margin-top: 15px
  &.tablet
    width: 50%
  &.phone
    padding: 0px 15px
    width: 100%
    .productName
      font-weight: 600
      font-size: 16px

.productContent
  background-color: var(--primary-background)
  box-sizing: border-box
  padding: 15px
  border-radius: 12px
  // cursor: pointer
  transition: all .2s ease-in-out
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  &:hover
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
    .itemTitle
      text-decoration: underline

.productImage
  height: 200px
  width: 200px
  img
    transition: all .2s ease-in-out
    height: 100%
    width: auto

.productName
  text-align: center
  font-weight: 500
  font-size: 14px
  padding: 10px 0px
  font-family: var(--poppins)
  color: var( --secondary-colordarkblue)
  width: 80%

.inblock
  padding: 40px

.textStyle
  padding-left: 70px
  .h1
    width: 55%
    font-size: 30px
    text-transform: uppercase
    font-weight: 300
    color: var(--primary-colorgold)
    font-family: var(--title-font)
    margin-top: 50px
  .h5
    width: 35%
    margin-top: 10px
    font-size: 16px
    font-weight: 600
    text-align: justify
    line-height: 22px
    font-family: var(--poppins)
    color: var(--primary-colorblue)
    margin-bottom: 80px

.productPrice
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  color: var(--color-gray)
  font-size: 20px

.pesitos
  font-size: 12px !important

.catalogPrice
  font-family: var(--poppins)
  font-weight: 400

.slider
  position: relative

.arrow
  position: absolute
  top: 100px
  cursor: pointer
  i
    font-size: 36px
    color: var(--primary-text-gray)
    transition: all 200ms ease-out
  &:hover
    i
      transform: scale(1.3)

.arrowLeft
  left: 8px
  z-index: 1

.arrowRight
  right: 8px
  z-index: 1
