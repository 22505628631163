#Career
    font-family: var(--poppins)
    &.phone
        .text
            width: 100%
        .title
            font-size: 26px
        .subtitle
            font-size: 24px
            font-weight: 600
        .textContainer
            width: 90%
        .containerButton
            width: 90% !important
        .button
            font-size: 14px
            width: 280px
            padding: 15px 25px
        .imageContainerMobile
            justify-content: flex-end
            margin-top: 20px !important
            margin-bottom: 20px
            img
                height: 210px
        .lineageTable
            width: 100%
            padding: 0px 16px
        .block
            display: flex
            margin-top: 30px
            background: var(--primary-background)
            margin: 20px 20px
            border-radius: 20px
            margin-left: 20px !important
            margin-right: 20px !important
            &.last
                flex-direction: row-reverse
                margin-bottom: 30px
                .textContainer
                    padding-left: 30px
                .imageContainer
                    justify-content: flex-end
                    margin-top: 45px !important
                    img
                        height: 300px
                .blockTitle
                    margin-top: 40px !important

#Titles
    padding: 16px 32px
    font-family: var(--poppins)
    background: linear-gradient( #F7F7F7 , #F6EFE6 50%)
    &.phone
        .text
            width: 100%
        .title
            font-size: 28px
        .subtitle
            font-size: 14px

.title
    padding: 0
    margin: 0
    color: var(--primary-colorblue)
    font-size: 32px
    font-weight: 500
    text-align: center
    font-family: var(--subtitle-font)
    margin-top: 10px

.subtitle
    color: var(--primary-colorgold)
    font-size: 30px
    margin: 0
    font-family: var(--title-font)
    text-align: center
    margin-top: 5px
    font-weight: 450
    text-transform: uppercase

.subtitle2
    color: var(--primary-colorblue)
    font-size: 14px
    margin: 0
    font-family: var(--poppins)
    text-align: center
    margin-top: 5px
    font-weight: 450

.block
    display: flex
    margin-top: 30px
    background: var(--primary-background)
    margin: 20px 20px
    border-radius: 20px
    margin-left: 180px !important
    margin-right: 180px !important
    &.last
        flex-direction: row-reverse
        margin-bottom: 30px
        .textContainer
            margin-left: -50px !important
        .imageContainer
            justify-content: flex-end
            margin-top: 45px !important
            margin-right: 0px
            img
                height: 250px
        .blockTitle
            margin-top: 16px !important

.imageContainer
    width: 25% !important
    margin-top: 20px !important
    margin-bottom: 10px !important
    align-content: left !important
    display: flex
    margin-bottom: 40px !important
    margin-right: 80px
    img
        height: 200px

.blockTitle
    padding: 8px 0
    font-size: 16px !important
    color: var(--primary-colorblue)
    margin-bottom: 15px
    font-weight: 600

.text
    width: 75%
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.textContainer
    width: 75%

.paragraph
    color: #002747
    font-size: 14px
    padding-bottom: 15px
    color: var(--primary-text-gray)

.button
    font-size: 16px
    width: 340px
    background-color: #002747 !important
    color: #fff
    margin-top: 15px
    padding: 15px 40px
    border-radius: 100px
    float: right !important
    margin-bottom: 30px !important
    &:hover
        background-color: var(--primary-colorgold) !important

.containerButton
    width: 75% !important
    align-content: center !important
    //padding: 8px 0
    margin-bottom: 15px
    display: flex !important
    font-weight: 600

.lineageTable
    width: 100%
    padding: 0px 80px

.lineageImage1
    width: 100%
